import { useCallback } from 'react';

import { fetchAggResponse } from '../../../common';
import {
	type ToggleDefinitionFunctionType,
	ToggleDefinitionMutation,
	type ToggleDefinitionMutationType,
	type ToggleDefinitionMutationVariables,
} from '../gql/toggle-definition-mutation';

type ToggleDefinitionSupplierType = {
	toggleDefinition: ToggleDefinitionFunctionType;
};

export const useToggleDefinitionSupplier = (): ToggleDefinitionSupplierType => {
	const toggleDefinition = useCallback(
		async ({ workspaceId, keyPhrase, isDisabled }: ToggleDefinitionMutationVariables) => {
			return fetchAggResponse<ToggleDefinitionMutationVariables, ToggleDefinitionMutationType>({
				graphQuery: ToggleDefinitionMutation,
				variables: {
					workspaceId,
					keyPhrase,
					isDisabled,
				},
			}).then((response) => {
				if (response?.data?.knowledgeDiscovery.updateUserKeyPhraseInteraction?.success) {
					return {
						toggleDefinitionSuccess: true,
					};
				} else {
					throw new Error('toggleDefinition mutation unsuccessful');
				}
			});
		},
		[],
	);

	return { toggleDefinition };
};
