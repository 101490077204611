import React, { Fragment, memo, type ReactNode, useEffect, useRef, useState } from 'react';

import { FormattedMessage } from 'react-intl-next';

import { IconButton } from '@atlaskit/button/new';
import Heading from '@atlaskit/heading';
import ChevronDownIcon from '@atlaskit/icon/utility/migration/chevron-down';
import ChevronRightIcon from '@atlaskit/icon/utility/migration/chevron-right';
import { fg } from '@atlaskit/platform-feature-flags';
import { Box, Inline, Stack, xcss } from '@atlaskit/primitives';

import { useAnalytics } from '../common/controller/analytics';
import { ErrorBoundary } from '../common/ui/error-boundary';
import { KnowledgeBadge } from '../knowledge-card-components';
import { RelatedEntityType } from '../related-entities-api';

import { onRelatedEntityClick, onSurfaceShown } from './analytics';
import { messages } from './messages';
import { useTopicBreakElems } from './set-break-point';
import { useRelatedTopics } from './use-related-topics';

export type AITopicsByPersonProps = {
	userId: string;
	userName: string;
	cloudId: string;
	sessionId: string;
	fallbackSection: ReactNode;
	v2styles?: boolean;
};

const topicSubheading = xcss({
	font: 'font.body.small',
	fontWeight: 'font.weight.regular',
	color: 'color.text.subtlest',
});

const AITopicsByPersonComponent = memo(
	({
		userId,
		userName,
		cloudId,
		sessionId,
		fallbackSection,
		v2styles = false,
	}: AITopicsByPersonProps) => {
		const [fireAnalyticsEvent] = useAnalytics();
		const { aiRelatedTopics, fetchRelatedTopics, showTopics } = useRelatedTopics();
		const wrapperRef = useRef<HTMLDivElement | null>(null);
		const [showMore, setShowMore] = useState<boolean>(false);
		const { breakElems } = useTopicBreakElems(wrapperRef, showTopics);

		const personCardSurfaceEnabled = fg('ai_topics_surface_person_knowledge_card');
		const shadowTrafficEnabled =
			fg('ai_topics_surface_person_card_shadow') && !personCardSurfaceEnabled;

		const onBadgeClickHandler = (topicId: string) => {
			fireAnalyticsEvent(
				onRelatedEntityClick({
					clickedEntityId: topicId,
					clickedEntityType: 'topic',
					principalEntityType: RelatedEntityType.USER,
					principalEntityId: userId,
					sessionId,
					location: 'personCard',
				}),
			);
		};

		useEffect(() => {
			fetchRelatedTopics({
				entityId: userId,
				principalEntityType: RelatedEntityType.USER,
				cloudId,
			});
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);

		useEffect(() => {
			if (showTopics && !shadowTrafficEnabled) {
				fireAnalyticsEvent(
					onSurfaceShown({
						principalEntityId: userId,
						principalEntityType: RelatedEntityType.USER,
						relatedTopics: aiRelatedTopics.map((topic) => topic.id),
						sessionId,
						location: 'personCard',
					}),
				);
			}
		}, [showTopics, shadowTrafficEnabled, aiRelatedTopics, userId, fireAnalyticsEvent, sessionId]);

		const firstName = userName.split(' ')[0];

		if (shadowTrafficEnabled) {
			return <Fragment>{fallbackSection}</Fragment>;
		}

		return showTopics ? (
			v2styles ? (
				<Box paddingInline="space.200" paddingBlockStart="space.150" paddingBlockEnd="space.100">
					<Stack space="space.075">
						<Box xcss={topicSubheading}>
							<FormattedMessage
								{...messages.ai_topics_surface_header_person}
								values={{ userName: firstName }}
							/>
						</Box>
						<Inline ref={wrapperRef} space="space.100" rowSpace="space.100" alignBlock="center">
							{(breakElems < 0
								? aiRelatedTopics
								: aiRelatedTopics.slice(0, Math.max(1, breakElems))
							).map((topic) => (
								<KnowledgeBadge
									key={topic.id}
									name={topic.name}
									url={`/wiki/topics/${topic.id}`}
									onBadgeClick={() => onBadgeClickHandler(topic.id)}
								/>
							))}
							{breakElems < aiRelatedTopics.length ? (
								<IconButton
									appearance="subtle"
									onClick={() => setShowMore((s) => !s)}
									icon={showMore ? ChevronDownIcon : ChevronRightIcon}
									label="Expand"
									spacing="compact"
								/>
							) : null}
						</Inline>
						{showMore && (
							<Box paddingBlockStart="space.025">
								<Inline space="space.100" shouldWrap>
									{aiRelatedTopics.slice(breakElems).map((topic) => (
										<KnowledgeBadge
											key={topic.id}
											name={topic.name}
											url={`/wiki/topics/${topic.id}`}
											onBadgeClick={() => onBadgeClickHandler(topic.id)}
										/>
									))}
								</Inline>
							</Box>
						)}
					</Stack>
				</Box>
			) : (
				<Box paddingBlockStart="space.200">
					<Stack space="space.100">
						<Heading size="xxsmall">
							<FormattedMessage
								{...messages.ai_topics_surface_header_person}
								values={{ userName: firstName }}
							/>
						</Heading>
						<Inline space="space.100" rowSpace="space.100" shouldWrap>
							{aiRelatedTopics.map((topic) => (
								<KnowledgeBadge
									key={topic.id}
									name={topic.name}
									url={`/wiki/topics/${topic.id}`}
									onBadgeClick={() => onBadgeClickHandler(topic.id)}
								/>
							))}
						</Inline>
					</Stack>
				</Box>
			)
		) : null;
	},
);

export const AITopicsByPerson = (props: AITopicsByPersonProps) => (
	<ErrorBoundary>
		<AITopicsByPersonComponent {...props} />
	</ErrorBoundary>
);
