import React, { Fragment, lazy, Suspense, useState } from 'react';

import { useIntl } from 'react-intl-next';

import { Checkbox } from '@atlaskit/checkbox';
import { FlagGroup } from '@atlaskit/flag';
import { ModalTransition } from '@atlaskit/modal-dialog';
import { fg } from '@atlaskit/platform-feature-flags';
import { Box, Flex, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { deleteSessionItem, getSessionItem, setSessionItem } from '@atlassian/search-dialog';

import { useAIAnswerContext } from '../../../ai-answer-dialog/ai-answer-context';
import { EditDefinitionModal } from '../curated/edit-definition/edit-definition-modal';
import { DefinitionEditHistory } from '../curated/edit-history/edit-history-modal';
import { toggleDefinitionErrorPayload } from '../error-analytics';

import { FeedbackButtons } from './feedback-buttons';
import { FeedbackModal } from './feedback-modal';
import { FeedbackOptionsModal } from './feedback-options-modal';
import { AIDefinitionFooterSection, EditedDefinitionFooterSection } from './footer-section';
import { messages } from './messages';
import { useToggleDefinitionSupplier } from './useToggleDefinitionSupplier';

const AkFeedbackFlag = lazy(() =>
	import(
		/* webpackChunkName: "@atlaskit-internal_@atlassian/product-search-dialog/feedback-flag" */ '@atlaskit/feedback-collector'
	).then(({ FeedbackFlag }) => {
		return {
			default: FeedbackFlag,
		};
	}),
);

const FeedbackButtonWrapperStyles = xcss({
	display: 'flex',
	alignItems: 'center',
	marginBottom: 'space.050',
	font: token('font.body'),
	color: 'color.text.accent.gray',
	paddingLeft: 'space.300',
	paddingRight: 'space.300',
});

const AI_DEFINITION_TOGGLE_CACHE_KEY = 'atlassian.search-ai.ai-definition-toggle';
interface DefinitionToggleCacheType {
	keyPhrase?: string;
}

export const DefinitionFooter = () => {
	const {
		answerEditor,
		fireAnalyticsEvent,
		readingAidsOptions,
		isAnswered,
		hasError,
		commonAttributes,
		definitionToggleEnabled,
	} = useAIAnswerContext();
	const { query: keyPhrase, workspaceId } = commonAttributes;

	const { formatMessage, locale } = useIntl();

	const [showFeedbackOptionsModal, setShowFeedbackOptionsModal] = useState(false);
	const [showEditDefinitionModal, setShowEditDefinitionModal] = useState(false);

	const [showFeedbackModal, setShowFeedbackModal] = useState(false);
	const [showEditHistoryModal, setShowEditHistoryModal] = useState(false);
	const [displayFlag, setDisplayFlag] = useState(false);

	const cachedTermDefinitionDisabled = JSON.parse(
		getSessionItem(AI_DEFINITION_TOGGLE_CACHE_KEY) || '{}',
	) as DefinitionToggleCacheType;
	const [termDefinitionDisabled, setTermDefinitionDisabled] = useState(
		cachedTermDefinitionDisabled.keyPhrase === keyPhrase,
	);

	const { definitionCurationOptions } = readingAidsOptions || {};
	const editDefinitionEnabled = !!definitionCurationOptions;

	const { toggleDefinition } = useToggleDefinitionSupplier();

	const onToggleDefinitionDisable = async () => {
		try {
			setTermDefinitionDisabled((current) => !current);
			await toggleDefinition({
				workspaceId,
				keyPhrase,
				isDisabled: !termDefinitionDisabled,
			});
			if (termDefinitionDisabled) {
				deleteSessionItem(AI_DEFINITION_TOGGLE_CACHE_KEY);
			} else {
				setSessionItem(AI_DEFINITION_TOGGLE_CACHE_KEY, JSON.stringify({ keyPhrase }));
			}
		} catch (error: unknown) {
			setTermDefinitionDisabled((current) => !current);
			fireAnalyticsEvent(toggleDefinitionErrorPayload(error, commonAttributes));
		}
	};

	return (
		<Fragment>
			{!hasError && isAnswered && (
				<Flex justifyContent="space-between" xcss={FeedbackButtonWrapperStyles}>
					<Box>
						<FeedbackButtons
							setShowFeedbackOptionsModal={setShowFeedbackOptionsModal}
							setDisplayFlag={setDisplayFlag}
						/>
					</Box>
					{definitionToggleEnabled && fg('kd_definitions_toggle_definition') && (
						<Checkbox
							isChecked={termDefinitionDisabled}
							label={messages.definition_footer_hide_term_definition_message.defaultMessage}
							value={messages.definition_footer_hide_term_definition_message.defaultMessage}
							onChange={onToggleDefinitionDisable}
						/>
					)}
				</Flex>
			)}
			{answerEditor ? (
				<EditedDefinitionFooterSection setShowEditHistoryModal={setShowEditHistoryModal} />
			) : (
				<AIDefinitionFooterSection />
			)}
			<ModalTransition>
				{showEditHistoryModal && editDefinitionEnabled && (
					<DefinitionEditHistory
						curatedDefinitionScopeConfig={definitionCurationOptions.curatedDefinitionScopeConfig}
						onClose={() => setShowEditHistoryModal(false)}
					/>
				)}
				{showFeedbackOptionsModal && (
					<FeedbackOptionsModal
						setShowFeedbackOptionsModal={setShowFeedbackOptionsModal}
						setShowEditDefinitionModal={setShowEditDefinitionModal}
						setShowFeedbackModal={setShowFeedbackModal}
						editDefinitionEnabled={editDefinitionEnabled}
					/>
				)}
				{showFeedbackModal && (
					<FeedbackModal
						setFeedbackModal={setShowFeedbackModal}
						isCuratedDefinition={!!answerEditor}
						setDisplayFlag={setDisplayFlag}
					/>
				)}
				{showEditDefinitionModal && editDefinitionEnabled && (
					<EditDefinitionModal
						curatedDefinitionScopeConfig={definitionCurationOptions.curatedDefinitionScopeConfig}
						closeEditModal={() => setShowEditDefinitionModal(false)}
						openFeedbackOptionsModal={() => setShowFeedbackOptionsModal(true)}
					/>
				)}
			</ModalTransition>
			{displayFlag && (
				<FlagGroup onDismissed={() => setDisplayFlag(false)}>
					<Suspense fallback={<></>}>
						<AkFeedbackFlag
							locale={locale}
							description={formatMessage(messages.definition_footer_feedback_flag_description)}
						/>
					</Suspense>
				</FlagGroup>
			)}
		</Fragment>
	);
};
