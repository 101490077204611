export interface ToggleDefinitionErrorType {
	message: string | null;
}

export interface ToggleDefinitionType {
	success: boolean;
	errors: ToggleDefinitionErrorType[];
}

export interface ToggleDefinitionMutationType {
	knowledgeDiscovery: {
		updateUserKeyPhraseInteraction: ToggleDefinitionType | null;
	};
}

export interface ToggleDefinitionMutationVariables {
	workspaceId: string;
	keyPhrase: string;
	isDisabled: boolean | null;
}

export const ToggleDefinitionMutation = `
	mutation ToggleDefinitionMutation(
		$workspaceId: String!
		$keyPhrase: String!
		$isDisabled: Boolean
		) {
		knowledgeDiscovery {
			updateUserKeyPhraseInteraction(
				input: {
					workspaceId: $workspaceId
					keyPhrase: $keyPhrase
					isDisabled: $isDisabled
				}
			) @optIn(to: "KnowledgeDiscovery Update User KeyPhrase Interaction") {
				success
				errors {
					message
				}
			}
		}
	}
`;

export type ToggleDefinitionFunctionReturnType = {
	toggleDefinitionSuccess: boolean;
};

export type ToggleDefinitionFunctionType = ({
	workspaceId,
	keyPhrase,
	isDisabled,
}: ToggleDefinitionMutationVariables) => Promise<ToggleDefinitionFunctionReturnType>;
