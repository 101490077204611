import React, { Fragment, Suspense, useCallback, useEffect, useRef, useState } from 'react';

import { useIntl } from 'react-intl-next';

import { FadeIn } from '@atlaskit/motion';
import { fg } from '@atlaskit/platform-feature-flags';
import { Box, xcss } from '@atlaskit/primitives';
import { useAIEventsInstrumentation } from '@atlassian/ai-analytics';

import { AISources, ErrorBoundary as CommonErrorBoundary, useAnalytics } from '../../common';
import { DotAnimationLoader } from '../../common/ui/ai-answer-loading';
import { AiBrand } from '../../constants';
import { messages } from '../../messages';
import { AIAnswerBody } from '../ai-answer-body';
import {
	AIAnswerResultBlurSection,
	AIAnswerResultBlurSectionText,
	AIAnswerResultSection,
} from '../ai-answer-body/ai-answer-result/styled';
import { AIAnswerBodyWrapper } from '../ai-answer-body/styled';
import { useAIAnswerContext } from '../ai-answer-context';
import { AIAnswerFooter } from '../ai-answer-footer';
import { AIAnswerLoadingDialog } from '../ai-answer-loading/ai-answer-loading-dialog';
import { AIAnswerTitle } from '../ai-answer-title';
import { onAIAnswerLoadingDialogShown, onAIAnswerSourceClick } from '../analytics';
import { ReadingAidsFollowUps } from '../reading-aids-follow-ups';
import {
	ReadingAidsContainer,
	ReadingAidsPopupContainer,
	ReadingAidsPopupContainerGlow,
	ReadingAidsStreamingContentWrapper,
	ReadingAidsWrapper,
} from '../styled';

const sourcesContainerStyles = xcss({
	paddingTop: '0',
	paddingRight: 'space.300',
	paddingBottom: 'space.150',
	paddingLeft: 'space.300',
});

const MAX_SOURCES = 4;

const MarkdownRenderer = React.lazy(() =>
	import(/* webpackChunkName: "react-markdown" */ 'react-markdown').then(
		({ default: ReactMarkdown }) => {
			return {
				default: ReactMarkdown,
			};
		},
	),
);

const StreamedReadingAidsPopupContent = () => {
	const { partialResponse } = useAIAnswerContext();
	const { formatMessage } = useIntl();
	const [isResultOverflow, setResultOverflow] = useState<boolean>(false);
	const [isResultExpanded, setResultExpanded] = useState<boolean>(false);
	const wrapperRef = useRef<HTMLDivElement | null>(null);
	const contentRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		if (!wrapperRef.current || !contentRef.current) {
			return;
		}
		const renderer = contentRef.current!;

		const mutateTelepointer = (mutations: MutationRecord[]) => {
			mutations.forEach(() => {
				if ((wrapperRef.current?.offsetHeight ?? 0) < (contentRef.current?.offsetHeight ?? 0)) {
					setResultOverflow(true);
				}
			});
		};
		const observer = new MutationObserver(mutateTelepointer);

		observer.observe(renderer, {
			characterData: true,
			attributes: false,
			childList: true,
			subtree: true,
		});

		return () => observer.disconnect();
	}, []);

	return (
		<FadeIn>
			{(props) => (
				<ReadingAidsWrapper onClick={(e) => e.stopPropagation()} {...props}>
					<AIAnswerTitle />
					<AIAnswerBodyWrapper>
						<AIAnswerResultSection
							id="ai-answer-streamed-result-section"
							ref={wrapperRef}
							isReadingAids={true}
							isExpanded={isResultExpanded}
						>
							<ReadingAidsStreamingContentWrapper ref={contentRef}>
								<Suspense fallback={<Fragment />}>
									<MarkdownRenderer>{partialResponse?.partialNlpResult ?? ''}</MarkdownRenderer>
								</Suspense>
							</ReadingAidsStreamingContentWrapper>
						</AIAnswerResultSection>
						{isResultOverflow && !isResultExpanded && (
							<AIAnswerResultBlurSection onClick={() => setResultExpanded(true)}>
								<AIAnswerResultBlurSectionText>
									{formatMessage(messages.ai_answer_expand_button_label)}
								</AIAnswerResultBlurSectionText>
							</AIAnswerResultBlurSection>
						)}
						<AIAnswerFooter />
					</AIAnswerBodyWrapper>
				</ReadingAidsWrapper>
			)}
		</FadeIn>
	);
};

const ReadingAidsPopupContent = () => {
	const {
		brand,
		commonAttributes,
		loading,
		callbacks,
		fireAnalyticsEvent,
		readingAidsOptions,
		partialResponse,
	} = useAIAnswerContext();
	const enableSmartLinkSources = readingAidsOptions?.enableSmartLinkSources;
	const { trackAIResultAction } = useAIEventsInstrumentation();
	const { searchSessionId, sources, extraAttributes } = commonAttributes;
	const readingAidsStreamingEnabled = !!extraAttributes?.featureGates?.readingAidsStreamingEnabled;

	const sourceClickCallback = useCallback(
		(index: number, hostname?: string) => {
			fireAnalyticsEvent(
				onAIAnswerSourceClick({
					sourceIndex: index,
					hostname,
					...commonAttributes,
				}),
			);
			trackAIResultAction('sourceLinkClicked');
			callbacks?.onAIAnswerSourceClick?.();
		},
		[fireAnalyticsEvent, commonAttributes, callbacks, trackAIResultAction],
	);

	if (loading) {
		if (!!partialResponse && readingAidsStreamingEnabled) {
			return <StreamedReadingAidsPopupContent />;
		}
		return <AIAnswerLoadingDialog />;
	}

	return (
		<FadeIn>
			{(props) => (
				<ReadingAidsWrapper onClick={(e) => e.stopPropagation()} {...props}>
					<AIAnswerTitle />
					<AIAnswerBody />
					<Box xcss={sourcesContainerStyles}>
						<AISources
							sources={sources}
							searchSessionId={searchSessionId}
							onClickHandler={sourceClickCallback}
							limit={MAX_SOURCES}
							verticalLayout={true}
							enableSmartLinkSources={enableSmartLinkSources}
						/>
					</Box>
					{brand === AiBrand.ROVO && (
						<CommonErrorBoundary>
							<ReadingAidsFollowUps />
						</CommonErrorBoundary>
					)}
					<AIAnswerFooter />
				</ReadingAidsWrapper>
			)}
		</FadeIn>
	);
};

export const DefinitionAnswerCard = () => {
	const { loading, commonAttributes } = useAIAnswerContext();
	const [fireAnalyticsEvent] = useAnalytics();
	const [continueLoadingAnimation, setContinueLoadingAnimation] = useState(true);
	const { source } = commonAttributes;

	useEffect(() => {
		if (!loading) {
			const countdownRemainingAnimation = setTimeout(() => {
				setContinueLoadingAnimation(false);
			}, 3000);

			return () => clearTimeout(countdownRemainingAnimation);
		}
	}, [loading]);

	const onLoadingAnimationShown = useCallback(() => {
		fireAnalyticsEvent(onAIAnswerLoadingDialogShown(commonAttributes, true));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fireAnalyticsEvent]);

	return (loading && fg('kd_definitions_loading_state_confluence')) ||
		(loading && fg('kd_definitions_loading_state_jira')) ? (
		<DotAnimationLoader
			onAnimationShown={onLoadingAnimationShown}
			source={source}
			data-testid="ai-answer-animated-loader"
		/>
	) : (
		<>
			{loading && <ReadingAidsPopupContainerGlow data-testid="ai-answer-legacy-loader" />}
			<ReadingAidsPopupContainer isLoading={loading} continueAnimation={continueLoadingAnimation}>
				<ReadingAidsContainer>
					<ReadingAidsPopupContent />
				</ReadingAidsContainer>
			</ReadingAidsPopupContainer>
		</>
	);
};
