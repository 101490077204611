// vendored from packages/editor/editor-plugin-ai/src/experience-application/screens-with-logic/LoadingScreenWithLogic.tsx
// similar logic also exists in packages/editor/renderer/src/ui/Renderer/index.tsx
import React, { Fragment, Suspense, useEffect, useMemo, useRef, useState } from 'react';

import { fg } from '@atlaskit/platform-feature-flags';
import { encodeAdf } from '@atlassian/conversation-assistant-content-renderer';

import { StatusCitation } from '../ai-answer-body/ai-answer-result/rendered/status-citation';

import { AIAnswerLoadingStreamMarkdownContainer, AIAnswerResultBlurSection } from './styled';

const ReactRenderer = React.lazy(() =>
	import(/* webpackChunkName: "@atlaskit-internal_.renderer" */ '@atlaskit/renderer').then(
		({ ReactRenderer }) => {
			return {
				default: ReactRenderer,
			};
		},
	),
);

const MarkdownRenderer = React.lazy(() =>
	import(/* webpackChunkName: "react-markdown" */ 'react-markdown').then(
		({ default: ReactMarkdown }) => {
			return {
				default: ReactMarkdown,
			};
		},
	),
);

const isScrolledToEnd = (element: HTMLElement | null) => {
	return (
		!!element && Math.abs(element.scrollHeight - element.clientHeight - element.scrollTop) < 10
	);
};

export const AIAnswerLoadingStream = ({ markdown }: { markdown: string }) => {
	const containerRef = useRef<HTMLDivElement>(null);
	const [showResultBlur, setShowResultBlur] = useState<boolean>(false);

	useEffect(() => {
		const renderer = containerRef.current!;

		const mutateTelepointer = (mutations: MutationRecord[]) => {
			mutations.forEach(() => {
				if (containerRef && !showResultBlur && !isScrolledToEnd(containerRef.current)) {
					setShowResultBlur(true);
				}
			});
		};
		const observer = new MutationObserver(mutateTelepointer);

		observer.observe(renderer, {
			characterData: true,
			attributes: false,
			childList: true,
			subtree: true,
		});

		return () => observer.disconnect();
	});

	const docContent = useMemo(() => encodeAdf(markdown, false), [markdown]);

	return (
		<>
			<AIAnswerLoadingStreamMarkdownContainer ref={containerRef}>
				<Suspense fallback={<Fragment />}>
					{fg('kd_smart_answers_use_adf_encoder') ? (
						<ReactRenderer
							document={docContent}
							nodeComponents={{
								status: (props) => <StatusCitation {...props} />,
							}}
						/>
					) : (
						<MarkdownRenderer>{markdown}</MarkdownRenderer>
					)}
				</Suspense>
			</AIAnswerLoadingStreamMarkdownContainer>
			{showResultBlur && <AIAnswerResultBlurSection />}
		</>
	);
};
