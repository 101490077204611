/**
 * @jsxRuntime classic
 * @jsx jsx
 */

import { useEffect } from 'react';

import { css, jsx } from '@compiled/react';
import Lottie from 'lottie-react';

import loadingAnimationData from './dot-animation.json';

const loadingAnimationOptions = {
	loop: true,
	autoplay: true,
	animationData: loadingAnimationData,
	rendererSettings: {
		preserveAspectRatio: 'xMidYMid slice',
	},
};

const loadingAnimationStyles = css({
	position: 'absolute',
	top: '-18px',
	left: '-11px',
	width: '50px',
	height: '16px',
	transform: 'scale(0.8)',
});

const jiraOverrideStyles = css({
	top: '-14px',
	left: '-12px',
});

type DotAnimationLoaderProps = {
	onAnimationShown: () => void;
	source?: string;
};
export const DotAnimationLoader = ({ onAnimationShown, source }: DotAnimationLoaderProps) => {
	useEffect(() => {
		onAnimationShown?.();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Lottie
			{...loadingAnimationOptions}
			css={[loadingAnimationStyles, source === 'jira-highlight-actions' && jiraOverrideStyles]}
		/>
	);
};
