import { useCallback, useState } from 'react';

import { defineMessages, useIntl } from 'react-intl-next';

import { fg } from '@atlaskit/platform-feature-flags';

import { useAIAnswerContext } from '../../../ai-answer-dialog/ai-answer-context';
import {
	AIAnswerAPIType,
	type AIAnswerQueryFunctionType,
	type SearchAIAnswerQueryVariables,
} from '../../../ai-answer-dialog/types';
import { fetchAggResponse } from '../../../common';
import {
	type FetchConfig as FetchConfigType,
	sainStreamFetchHandlerAssistanceService,
} from '../../../stream-api/stream-fetch';
import {
	fetchCuratedDefinitionErrorPayload,
	fetchPrecomputedDefinitionErrorPayload,
} from '../error-analytics';
import {
	CURATED_DEFINITION_OPERATION_NAME,
	CuratedDefinitionQuery,
	type CuratedDefinitionQueryType,
	type CuratedDefinitionQueryVariables,
} from '../gql/curated-definition-query';
import {
	PrecomputedDefinitionQuery,
	type PrecomputedDefinitionQueryType,
	type PrecomputedDefinitionQueryVariables,
} from '../gql/precomputed-definition-query';
import type { DefinitionConfluenceScope, DefinitionJiraScope } from '../gql/types';

import { mapCustomDefinitionToNLPSearchType } from './utils';

type useDefinitionSupplierArgs = {
	workspaceId: string;
	cloudIdARI: string;
	fetchConfig?: FetchConfigType;
	confluenceScopeId?: DefinitionConfluenceScope;
	jiraScopeId?: DefinitionJiraScope;
	isDefinitionCurationEnabled?: boolean;
};

type DefinitionSupplierType = {
	fetchDefinition: AIAnswerQueryFunctionType;
	loading: boolean;
};

const i18n = defineMessages({
	searchAiDialogQueryPretext: {
		id: 'contextual-reading-aids.search-ai-dialog.query-pretext',
		defaultMessage: 'what is {queryText}?',
		description: 'Search query prompt for a highlighted term that a user wants to be defined',
	},
});

const NO_CURATED_DEF_FOUND_MESSAGE = 'No matching scope for Definition';

export const useDefinitionSupplier = ({
	workspaceId,
	cloudIdARI,
	fetchConfig,
	confluenceScopeId,
	jiraScopeId,
	isDefinitionCurationEnabled,
}: useDefinitionSupplierArgs): DefinitionSupplierType => {
	const intl = useIntl();
	const [loading, setLoading] = useState(true);
	const { fireAnalyticsEvent, commonAttributes } = useAIAnswerContext();

	const fetchAIGeneratedDefinition = useCallback(
		async ({ variables }: { variables: Omit<SearchAIAnswerQueryVariables, 'cloudIdARI'> }) => {
			const { query, locale, additional_context, experience, followUpsEnabled } = variables;

			if (
				(!!confluenceScopeId && fg('kd_enable_precomputed_definitions')) ||
				(!!jiraScopeId?.issueId && fg('kd_enable_jira_precomputed_definitions'))
			) {
				try {
					const contentId = confluenceScopeId?.contentId ?? jiraScopeId?.issueId;

					const precomputedResult = await fetchAggResponse<
						PrecomputedDefinitionQueryVariables,
						PrecomputedDefinitionQueryType
					>({
						graphQuery: PrecomputedDefinitionQuery,
						variables: {
							workspaceId,
							keyPhrase: variables.query,
							contentId: contentId || '',
						},
					});

					if (
						precomputedResult.data &&
						!precomputedResult.data.knowledgeDiscovery.autoDefinition.message
					) {
						const data = {
							nlpSearch: mapCustomDefinitionToNLPSearchType(
								precomputedResult.data.knowledgeDiscovery.autoDefinition,
							),
						};
						setLoading(false);
						return data;
					} else if (
						precomputedResult.data &&
						precomputedResult.data.knowledgeDiscovery.autoDefinition.message
					) {
						throw new Error(precomputedResult.data.knowledgeDiscovery.autoDefinition.message);
					} else {
						throw new Error('Something went wrong with fetching precomputed definition');
					}
				} catch (error) {
					if (
						error instanceof Object &&
						'message' in error &&
						error.message !== NO_CURATED_DEF_FOUND_MESSAGE
					) {
						fireAnalyticsEvent(fetchPrecomputedDefinitionErrorPayload(error, commonAttributes));
					}
				}
				// Fallthrough to fetching the AI generated definition if there is no precomputed definition or the network call fails
			}

			return {
				type: AIAnswerAPIType.STREAM,
				stream: sainStreamFetchHandlerAssistanceService({
					variables: {
						query: intl.formatMessage(i18n.searchAiDialogQueryPretext, {
							queryText: query,
						}),
						locale,
						cloudIdARI,
						additional_context,
						experience,
						followUpsEnabled,
					},
					isReadingAids: true,
					fetchConfig,
					generatorConfig: {
						yieldAnswerPart: true,
						yieldFinalResponse: true,
					},
					onStreamFinalResponse: () => {
						setLoading(false);
					},
				}),
			};
		},
		[
			cloudIdARI,
			fetchConfig,
			intl,
			commonAttributes,
			fireAnalyticsEvent,
			workspaceId,
			confluenceScopeId,
			jiraScopeId,
		],
	);

	const fetchDefinition = useCallback(
		async ({ variables }: { variables: Omit<SearchAIAnswerQueryVariables, 'cloudIdARI'> }) => {
			if (isDefinitionCurationEnabled) {
				try {
					const jiraProjectScopeId = { projectId: jiraScopeId?.projectId || '' };
					const result = await fetchAggResponse<
						CuratedDefinitionQueryVariables,
						CuratedDefinitionQueryType
					>({
						graphQuery: CuratedDefinitionQuery,
						variables: {
							workspaceId,
							keyPhrase: variables.query,
							confluenceScopeId,
							jiraScopeId: jiraProjectScopeId,
						},
						operationName: CURATED_DEFINITION_OPERATION_NAME,
					});

					if (result.data && !result.data.knowledgeDiscovery.definition.message) {
						const data = {
							nlpSearch: mapCustomDefinitionToNLPSearchType(
								result.data.knowledgeDiscovery.definition,
							),
						};
						setLoading(false);
						return data;
					} else if (result.data && result.data.knowledgeDiscovery.definition.message) {
						throw new Error(result.data.knowledgeDiscovery.definition.message);
					} else {
						throw new Error('Something went wrong with fetching curated definition');
					}
				} catch (error) {
					// want to fire event when error.message does not equal the no curated definition message
					// returned by the query in data.knowledgeDiscovery.definition.message
					if (
						error instanceof Object &&
						'message' in error &&
						error.message !== NO_CURATED_DEF_FOUND_MESSAGE
					) {
						fireAnalyticsEvent(fetchCuratedDefinitionErrorPayload(error, commonAttributes));
					}
					return fetchAIGeneratedDefinition({ variables });
				}
			}

			return fetchAIGeneratedDefinition({ variables });
		},
		[
			confluenceScopeId,
			jiraScopeId,
			fetchAIGeneratedDefinition,
			isDefinitionCurationEnabled,
			workspaceId,
			commonAttributes,
			fireAnalyticsEvent,
		],
	);

	return { fetchDefinition, loading };
};
